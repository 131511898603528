
.iconButton {
  border-radius: 10px;
  padding: 10px;
  border: none;
  background: transparent;
  cursor: pointer;
}
.iconButton:hover {
    color: #6b97f6;
    transition: all 0.1s linear;
    transform: scale(1.03);
}
.flex {
  display: flex;
  align-items: center;
}
.blueCircle {
  color: #6b97f6;
  border-radius: 100%;
  border: 1px solid #6b97f6;
  padding: 10px;
  width: 39px;
  height: 39px;
  cursor: pointer;
}
.blueCircle:hover {
    color: white;
    background: #6b97f6;
    transition: all 0.1s linear;
    transform: scale(1.03);
}
