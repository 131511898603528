body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#printJS-Modal {
  position: fixed !important;
  width: 100% !important;
  height: 100% !important;
  text-align: center !important;
  z-index: 1301 !important;
  line-height: 100vh !important;
  background-color:rgba(255,255,255,0.2) !important;
  color: black !important;
  font-size: 40px !important;
  font-family: inherit !important;
  margin-bottom: -7px !important;
  font-weight: normal !important;
}
html {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.spin {
  animation: fa-spin 2s infinite linear;  
}
.pulse {
  -webkit-animation: fa-pulse 1s infinite steps(8);
  animation: fa-pulse 1s infinite steps(8);
}


@keyframes fa-spin {
  0% {
    transform: scaleX(-1) rotate(359deg);
  }
  100% {
    transform: scaleX(-1) rotate(0deg);
  }
}
@-webkit-keyframes fa-pulse {
  0% {
    -webkit-filter: opacity(1);
    filter: opacity(1);
  }
  50% {
    -webkit-filter: opacity(0.6);
    filter: opacity(0.6);
  }
  100% {
    -webkit-filter: opacity(1);
    filter: opacity(1);
  }
}
@keyframes fa-pulse {
  0% {
    -webkit-filter: opacity(1);
    filter: opacity(1);
  }
  50% {
    -webkit-filter: opacity(0.6);
    filter: opacity(0.6);
  }
  100% {
    -webkit-filter: opacity(1);
    filter: opacity(1);
  }
}
.hover:hover{
  cursor: pointer;
  filter:  opacity(1) !important;
  box-shadow: inset 0px 0px 1px 0px rgba(0,0,0,1);
 
  
}

.rs-picker-menu {
  z-index: 102 !important;
}
div#fps:hover {
  height: 100px !important;
}
div#version:hover {
  background: lightgray !important;
  transition: all 0.1s;
}


 /* make the nodes round and change their background-color */

 .tf-custom .tf-nc {
 border: none;
  padding: 0;

}

/* make the horizontal and vertical connectors thick and change their color */

.tf-custom .tf-nc:before,
.tf-custom .tf-nc:after {
  border-left-color: #264e6e;
  border-left-width: 2px;
}
  
.tf-custom .tf-nc:after {
  border-left-color: #264e6e;
  border-left-width: 2px;
  height: 5em;
}

.tf-custom li li:before {
  border-top-color: #264e6e;
  border-top-width: 2px;
}
.tf-custom  li ul{
  margin: 6em 0 0 0 ;
}

.active-status>div{
  min-width: 150px ;
  font-size: 14px;
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0,0,0,0.3);
}
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: rgb(245, 245, 245);
}
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: rgb(129, 126, 126);
}