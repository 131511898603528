.switch {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 20px;
  cursor: pointer;
  position: relative;
}
.ballContainer {
  width: 40px;
  height: 20px;
  border: 1px solid #ccc;
  border-radius: 20px;
  cursor: pointer;
  position: relative;
}

.ball {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #eee;
  position: absolute;
  top: 1px;
  left: 1px;
  transition: transform 0.2s ease-in-out;
}

.active {
  background-color: green;
  transform: translateX(20px);
}

.inactive {
  background-color: #f6c23e;
  transform: translateX(0);
}

.notificationsDisabled {
  background-color: rgb(244, 67, 54);
  transform: translateX(0);
}

.label {
  font-size: 14px;
  color: #444;
}
