.mainTitle{
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}
.title{
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 6px;
}
.statisticTitle{
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 6px;
}
.statisticNumber{
    font-size: 25px;
    margin: 10px;
}
.statisticNumber span{
    font-size: 16px;
    font-weight: bold;
    font-weight: normal;
}
.textMuted{
    color: #6c757d;
    font-size: 12px;
}
.cell{
    font-size: 16px;
}