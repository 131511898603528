.badgeContainer {
  border-radius: 50%;
  background: #6b97f6;
  font-weight: 700;
  font-size: 13px;
  color: #fff;
  line-height: 15px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.badge {
  position: relative;
  display: inline-block;
}

.dot {
  width: 7px;
  height: 7px;
  border-radius: 100%;
  position: absolute;
}

.top-left {
  top: 0;
  left: 0;
}

.top-right {
  top: 0;
  right: 0;
}

.bottom-left {
  bottom: 0;
  left: 0;
}

.bottom-right {
  top: 70%;
  left: 99%;
}