.chatContainer {
  min-height: 96vh;
  max-height: 96vh;

  box-sizing: border-box;
  position: relative;
  border: 1.5px solid #c7c6cb;
  max-width: 350px;
  top: 4px;
  min-width: 350px;
  margin-right: 14px;
  padding: 18px;
  background: #fff;
  display: flex;
  flex-direction: column;
}
.chatContainerPage {
  box-sizing: border-box;
  position: relative;
  border: 1.5px solid #c7c6cb;
  border-radius: 5px;
  max-width: 350px;
  min-width: 350px;
  margin-right: 14px;
  padding: 18px;
}
.openChatBackground {
  background: #e7eeff;
}

.chatTitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-transform: capitalize;
  color: #6b97f6;
}

.sectionsContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  padding: 6px;
  gap: 11px;
}

.list {
  gap: 10px;
  padding: 0;
  display: flex;
  flex-direction: column;
  list-style: none;
  max-width: 100%;
  max-height: 250px;
  overflow-y: auto;
}

/* li:not(:first-child) {
  padding-left: 10px;
} */

.listContainer p {
  color: #000;
  font-weight: 600;
  font-size: 20px;
}

.applicantList li:first-child {
  background: none;
  padding-left: 10px;
}
.applicantList li:first-child :hover {
  background: #e7eeff;
  transition: all 0.1s linear;
  cursor: pointer;
}
.applicantList li:not(:first-child) {
  padding-left: 10px;
}

.listItem {
  display: flex;
  min-height: 42px;
  align-items: center;
  border-radius: 5px;
  padding-left: 7px;
  justify-content: flex-start;
  gap: 20px;
  color: #5e5d65;
}
.listItem:hover {
  background: #e7eeff;
  transition: all 0.1s linear;
  cursor: pointer;
}

.closeIcon {
  display: none;
  color: #6b97f6;
  padding-left: 0px;
  margin-right: 5px;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.listItem:hover .closeIcon {
  display: flex;
}

.closeIcon :hover {
  color: #fff;
  border-radius: 100%;
  display: flex;
  cursor: pointer;
  background: #6b97f6;
}
.divider {
  border-top: 1px solid #a5aec1;
}
.text {
  white-space: nowrap;
  flex: 0 1 auto;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 0px;
}
.openChatHeader {
  gap: 15px;
  height: 45px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 0px 5px;
  background: linear-gradient(90deg, #6b97f6 -25%, #b7cdff 135%);
}

.selectedChatName {
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  color: #fff;
  margin: 10px 0px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.phomeIcon {
  margin-left: auto;
  color: #6b97f6;
  background: #fff;
  border-radius: 50%;
  padding: 6px;
  display: flex;
  justify-content: center;
}

.backIcon {
  display: flex;
  justify-content: center;
  color: #fff;
  border: none;
  background: none;
  padding: 5px;
  cursor: pointer;
}
.backIcon:hover {
  border-radius: 50%;
  transition: all 0.1s linear;
  transform: scale(1.03);
}
.sendIcon {
  display: flex;
  justify-content: center;
  background: linear-gradient(90deg, #f2798e -0.11%, #708ec9 99.89%);
  color: #fff;
  border-radius: 50%;
  border: none;
  padding: 6px;
  margin-left: auto;
  cursor: pointer;
}
.sendIcon:hover {
  transition: all 0.1s linear;
  transform: scale(1.03);
}
.openChat {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
}
.newMessageContainer {
  margin-top: auto;
}
.newMessage {
  padding: 0px 5px;
  background: #fff;
  gap: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.newMessage:hover:not(:disabled) {
  box-shadow: 0px 0px 4px -3px black;
}
.newMessageTopRadius {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.messagesContainer {
  margin: 20px 0px;
  flex: 1 1 90%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.bubble {
  flex: 1 1 auto;
  clear: both;
} /* clear the floats here on parent */
.bubble p {
  border-radius: 5px;
  padding: 8px;
  margin: 12px 12px;
  max-width: 80%; /* this will make it not exceed 80% and then wrap */
  position: relative;
  transition: background-color 0.5s;
}
.left p {
  background-color: #fff;
  float: left;
  color: #000;
} /* floated left */
.right p {
  background-color: #b7cdff;
  color: #fff;
  float: right;
} /* floated right */
.delivered p {
  background-color: #6b97f6;
}
/* classes below are only for arrows, not relevant */
.left p::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  left: -8px;
  top: 8px;
  border-top: 4px solid transparent;
  border-right: 8px solid #fff;
  border-bottom: 4px solid transparent;
}
.right p::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  right: -8px;
  bottom: 8px;
  border-top: 4px solid transparent;
  border-left: 8px solid #6b97f6;
  border-bottom: 4px solid transparent;
}
.message {
  white-space: pre-line;
}
.unseenMessagesTimestamp {
  white-space: nowrap;
}
.messageDate {
  position: absolute;
  display: flex;
  align-items: center;
  white-space: nowrap;
  bottom: -20px;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #5e5d65;
}
.messageDateLeft {
  left: 0;
}
.messageDateRight {
  right: 0;
}
.messageLabel {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #5e5d65;
  display: flex;
  justify-content: center;
}

.attachment {
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
}

.warning {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: white;
  padding: 10px;
  color: black;
  border: 1px solid red;
}

.file {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  color: #5e5d65;
  border: 2px solid #6b97f6;
  border-radius: 15px;
  padding: 10px;
}
.messageAttachmentContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10px 0px;
  gap: 5px;
}
.messageAttachment {
  justify-content: center;
  display: flex;
  align-items: center;
  border: 1px solid #6b97f6;
  border-radius: 10px;
  padding: 10px;
  background: white;
  color: #000;
  gap: 5px;
}
.attachments {
  display: flex;
  background: #fff;
  padding: 15px;
  overflow-x: auto;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  gap: 10px;
}

.deleteAttachment {
  border-radius: 100%;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: -11px;
  right: -14px;
  background: white;
  border: none;
  padding: 4px;
  cursor: pointer;
}
.deleteAttachment:hover {
  transition: all 0.1s linear;
  transform: scale(1.03);
}
.rotatingCircle {
  animation: rotating_circle 1300ms linear infinite normal forwards;
}
@keyframes rotating_circle {
  0% {
    transform: translate(41.5899px, 42.0001px) rotate(0deg);
  }
  100% {
    transform: translate(41.5899px, 42.0001px) rotate(351deg);
  }
}

.switchesContainer {
  display: flex;
  gap: 70px;
  margin-bottom: 5px;
}
