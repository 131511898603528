.container {
  position: relative;
}
.tableContainer {
  position: absolute;
  top: 100%;
  z-index: 9999;
  width: 311px;
  background: #ffffff;
  margin-top: 10px;
  box-shadow: 1px 10px 16px -3px rgba(133, 131, 133, 1);
  border-radius: 5px;
  right: 0;
  max-height: 30vh;
  overflow: auto;
  color:#000
}
.topBorders {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.table tr {
  height: 35px;
}
.table tr:hover {
  background: #f3f3f3;
}
.table thead {
  background: #f3f3f3;
  height: 40px;
}
.table tr:nth-child(1) {
  border-top: solid 0.5px #a5aec1;
}
.table tr:not(:last-child) {
  border-bottom: solid 0.5px #a5aec1;
}
.table td,th {
  padding-left: 5px;
  width: 25%;
}
.table {
  border-collapse: collapse;
  width: 100%;
  height: 100%;
}
.headerIcon {
  margin-left: 5px;
}
.tableHeaderContainer {
  background: #b7cdff;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 500;
  font-size: 18px;
}
