.table-container {
  display: flex;
  flex-direction: column;
  font-family: Arial, sans-serif;
}

.state-section {
  margin-bottom: 20px;
}

.state-header {
  background-color: #254e70;
  padding: 10px;
  font-weight: bold;
  font-size: 1.2em;
  border-radius: 5px;
  color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.state-table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.state-table th,
.state-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
  vertical-align: top;
}

.state-table th {
  background-color: #e4e4e4;
  font-weight: bold;
  max-width: 20%;

  color: black;
}

.score-header {
  background-color: #e4e4e4;
  font-weight: bold !important;
  color: black;
  padding: 8px;
  text-align: center;
  vertical-align: middle !important;
}

.lender {
  padding: 3px 6px;
  margin: 2px;
  border-radius: 3px;
  font-size: 0.9em;
  display: flex;
  align-items: center;
  gap: 5px;
}

.lender.score {
  color: #fff;
}

.lender {
  transition: transform 0.2s, background-color 0.2s;
}

.lender:hover {
  transform: scale(1.05);
}
.scores-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px; /* Adjust the gap between lenders */
}
.total-decisions {
  background-color: #e4e4e4;
  color: black;
  font-weight: 500;
  width: 100%;
  padding: 3px 6px;
  border-radius: 5px;
}
.no-decisions {
  background-color: #e4e4e4;
  color: black;
  font-weight: 500;
  width: 100%;
  padding: 3px 6px;
  border-radius: 5px;
}
